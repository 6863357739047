.divTable {
  display: table;
  width: 100%;
}
.divTableRow {
  display: table-row;
}

.divTableCell {
  display: table-cell;
  padding: 10px 10px;
}

.divTableBody {
  display: table-row-group;
}

.divTableContainer {
  margin: 10px;
  padding-top: 20px;
}
