.flexContainer{
  display: flex !important;
  flex-direction: row;
}

.card {
  display: -webkit-inline-box;
  width: 30%;
  min-width: 260px;
  max-width: 260px;
  padding: 0px;
  flex-wrap: nowrap !important;
  flex-direction: column !important;
  background-color: white;
  height: calc(100vh - 68px);
  min-height: 675px;
  position: relative;
  border: 1px solid #e0e0e0;

  > div {
    display: inline-block;
  }
  > hr {
    margin-top: 25px;
  }
}

.tabContainer{
  width: 70%;
  min-width: 900px;
  max-width: 1500px;
}