// ==== Causten
@font-face {
  font-family: 'Causten Bold';
  src: url(../fonts/CaustenRound/CaustenRound-Bold.woff) format('woff');
}

@font-face {
  font-family: 'Causten Medium';
  src: url(../fonts/CaustenRound/CaustenRound-Medium.ttf) format('woff');
}

@font-face {
  font-family: 'Inter Regular';
  src: url(../fonts/Inter/Inter-Regular.ttf);
}

@font-face {
  font-family: 'Inter Semibold';
  src: url(../fonts/Inter/Inter-SemiBold.ttf);
}

@font-face {
  font-family: 'Roboto Regular';
  src: url(../fonts/Roboto/Roboto-Regular.ttf);
}

* {
  box-sizing: border-box;
}

@import '../../assets/ui-kit/_variables';

html,
body,
#fullheight,
#root {
  // min-height: 100% !important;
  height: 100% !important;
  background-color: #f5f5f5;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-smoothing: antialiased;
  margin: 0px;
  transition: 0.2s ease;
}

//override styling on all org timesheet table
.allOrgReg {
  .projectCellContainer {
    padding-left: 44px;
  }

  .taskContainer {
    .taskTitle {
      word-break: break-word;
      margin-left: 25px;
    }
  }

  .taskPickerButton {
    margin-left: 55px;
  }

  .taskPickerInput {
    margin-left: 60px;
    position: relative;
    > div > div > div {
      margin-right: 60px;
    }
    > div > div > div > div {
      position: absolute;
      right: -30px;
    }
  }

  .projPickerButton,
  .projPickerInput {
    margin-left: 35px;
  }
}

.marginB28 {
  margin-bottom: 28px !important;
}

.task_table .MuiDataGrid-virtualScroller {
  overflow: unset !important;
}

.task_datagrid .MuiDataGrid-row {
  max-height: fit-content !important;
}

.mobile_bg .MuiDrawer-paper {
  background-color: rgb(4, 24, 28);
}

#sign-in-error a {
  color: #0091ea !important;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.infoAlertInvitation {
  .MuiPaper-root {
    padding: 0 10px;
    border-radius: 0;
    background-color: #e9f5fe;
    color: #0d3c61;
    border: 1px solid #64c1ff;
    width: 100%;
    padding-left: 15px;
    // width: calc(100% - 60px) !important;
    > div {
      padding: 0;
    }
  }
}

.dashboardHeight {
  > .MuiBox-root {
    // min-height: 100% !important;
    height: 100% !important;
  }
}
// Snackbar
.snackbar-collapsed {
  align-items: flex-start;
  -webkit-flex-direction: column-reverse;
  -ms-flex-direction: column-reverse;
  flex-direction: column-reverse;
  display: flex;
  position: fixed;
  bottom: 15px;
  > div {
    position: unset;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    max-height: 100%;
    margin-top: 10px;
  }
  > div > div {
    position: relative;
    display: block;
    margin: 0 auto;
  }

  > div > div > div {
    display: inline-block;
    line-height: 0;
    margin-top: 0 !important;
    vertical-align: middle;
  }
}
// drawer collapsed
.MuiBox-root.css-154d074
  .snackbar-collapsed
  > .MuiSnackbar-anchorOriginBottomLeft {
  width: calc(100% - 60px) !important;
}
.MuiBox-root.css-1nq4yeg
  .snackbar-collapsed
  > .MuiSnackbar-anchorOriginBottomLeft {
  width: calc(100% - 215px) !important;
}

.MuiBox-root.css-1nq4yeg
  .snackbar-collapsed--we-timesheet
  > .MuiSnackbar-anchorOriginBottomLeft {
  left: 50.1% !important;
  transition: all 0.35s ease-in-out;
}

.MuiBox-root.css-154d074
  .snackbar-collapsed--we-timesheet
  > .MuiSnackbar-anchorOriginBottomLeft {
  left: 50% !important;
  transition: all 0.35s ease-in-out;
}

.MuiBox-root.css-154d074
  .snackbar-collapsed--history
  > .MuiSnackbar-anchorOriginBottomLeft {
  left: 52% !important;
  transition: all 0.35s ease-in-out;
}
.MuiBox-root.css-1nq4yeg
  .snackbar-collapsed--history
  > .MuiSnackbar-anchorOriginBottomLeft {
  left: 57.5% !important;
  transition: all 0.35s ease-in-out;
}

@media screen and (min-width: 1919px) {
  .infoAlertInvitation {
    left: -222px !important;
    .MuiPaper-root {
      padding-left: 238px;
    }
  }
}

@media screen and (max-width: 1023px) {
  .infoAlertInvitation {
    left: 0px !important;
    .MuiPaper-root {
      padding-left: 20px;
    }
  }
}

@media screen and (max-width: 740px) {
  .allOrgReg {
    .projectCellContainer {
      padding-left: 20px;
    }
    .projPickerButton,
    .projPickerInput {
      margin-left: 10px;
    }
  }
  .taskPickerButton {
    margin-left: 35px !important;
  }
}

.leaveTable {
  .MuiDataGrid-footerContainer {
    justify-content: center;
  }
  .MuiDataGrid-root {
    border: none;
  }
  .MuiBox-root {
    padding: 0;
    margin-top: 20px;
  }
  .MuiAvatar-root {
    height: 24px;
    width: 24px;
    margin-right: 8px;
  }
  .MuiButtonBase-root {
    min-width: 0px;
  }
}

.cancelLabel {
  .MuiButtonBase-root {
    color: #00000099;
  }
}

.SidebarBackgroundColor {
  .Mui-selected {
    background-color: #e1dfdd !important;
  }
  .MuiTypography-root {
    padding-left: 15px;
  }
  .MuiButtonBase-root {
    padding-left: 33px !important;
  }
  .MuiList-root {
    padding-top: 0px !important;
  }
}

.padT10 {
  padding-top: 10px;
}

.padT15 {
  padding-top: 15px;
}

.padT2 {
  padding-top: 2px;
}

.padT3 {
  padding-top: 3px;
}
.padT4 {
  padding-top: 4px;
}
.padT5 {
  padding-top: 5px;
}
.padT6 {
  padding-top: 6px;
}
.padT7 {
  padding-top: 7px;
}

.padR5 {
  padding-right: 5px;
}

.padR6 {
  padding-right: 6px;
}

.padR7 {
  padding-right: 7px;
}

.padR10 {
  padding-right: 10px;
}
.padR20 {
  padding-right: 20px;
}

.padR3 {
  padding-right: 3px;
}
.padR4 {
  padding-right: 4px;
}

.LinearProgress {
  height: 0 !important;
  .MuiLinearProgress-root {
    top: -3px;
    z-index: 9999;
    left: 5px;
  }
}

.padL3 {
  padding-left: 3px;
}

.padL4 {
  padding-left: 4px;
}

.padL5 {
  padding-left: 5px;
}

.buttonNotUpperCase {
  .MuiTypography-root {
    text-transform: none !important;
  }
}
.padB5 {
  padding-bottom: 5px;
}

.padB10 {
  padding-bottom: 10px;
}

.padB20 {
  padding-bottom: 20px;
}

.padB30 {
  padding-bottom: 30px;
}

.padB40 {
  padding-bottom: 40px;
}

.padB50 {
  padding-bottom: 50px;
}

.padB60 {
  padding-bottom: 60px;
}

.padB70 {
  padding-bottom: 70px;
}

.padB80 {
  padding-bottom: 80px;
}
.padB90 {
  padding-bottom: 90px;
}

.MuiBackdrop-root {
  background-color: rgba(0, 0, 0, 0.8) !important;
  opacity: 0.08 !important;
  transition: ease-in;
}

.CheckboxWhiteColor {
  .MuiButtonBase-root {
    color: white !important;
  }
}

.menuReg .MuiBackdrop-root {
  background-color: #ffffff00 !important;
}

.DataGridHeader {
  .MuiDataGrid-columnHeaders {
    background-color: rgba(0, 184, 212, 0.08);
  }
}

.capitalize {
  text-transform: capitalize !important;
}

.AccourdionHeader {
  .MuiAccordionSummary-root {
    background-color: rgba(0, 184, 212, 0.08) !important;
  }
  .MuiAccordionSummary-content {
    background-color: rgba(166, 229, 239, 0.08) !important;
  }
}

.ButtonSelectALL {
  .MuiFormControlLabel-root {
    margin-right: 0px !important;
  }
}

.tolowercase {
  text-transform: lowercase !important;
}

.teams_projects {
  > .MuiPaper-root {
    border: 1px solid $gray-light4 !important;
    box-shadow: none;
    margin: 10px 0 !important;
    border-radius: 4px;
  }
  > .MuiPaper-root::before {
    background-color: unset;
  }
}
.textCenter {
  text-align: center;
}

.PanelCustom {
  .MuiBox-root {
    padding: 0px 0px !important ;
  }
}

.AutcompleteFullWith {
  .MuiAutocomplete-root {
    width: 100%;
  }
}

.padT11 {
  padding-top: 11px;
}

.padT12 {
  padding-top: 12px;
}

.padT8 {
  padding-top: 8px;
}
.padT9 {
  padding-top: 9px;
}

.btnPrimaryDatagrid {
  .MuiButtonBase-root {
    color: #00b8d4 !important;
  }
}

.timesheetTable {
  .MuiTableContainer-root {
    overflow: unset !important;
  }
  .MuiDataGrid-cell {
    padding: 0px !important;
  }
}

// @media screen and (min-width: 1365px) {
//   .dataGridTimesheet {
//     .MuiDataGrid-cell {
//       min-width: 115px !important;
//     }
//   }
// }
