.closeIcon {
  display: none !important;
  color: white;
  font-size: 22px !important;
}

.closeIconButton {
  position: absolute !important;
  right: 5px;
  top: 0;
  bottom: 0;
}

.closeIconButton:hover {
  background-color: rgba(255, 255, 255, 0.1) !important;
}
.alertMessage {
  display: inline;
}
// .snackbar_container {
//   left: 240px !important;
//   > div {
//     display: flex !important;
//     min-width: 400px;
//     padding-right: 40px;
//   }
//   button {
//     height: fit-content;
//     margin-top: 10px;
//   }
// }

@media screen and (max-width: 900px) {
  .closeIcon {
    display: block !important;
  }
}
@media screen and (max-width: 740px) {
  .snackbar_container {
    > div {
      min-width: auto;
    }
  }
}
