.delete_button {
  font-size: 14px !important;

  &:hover {
    background-color: rgba(0, 0, 0, 0.04);
    border-radius: 50%;
  }
}

.task_picker_button {
  margin-left: 52px !important;
}

button.task_picker_button,
div.task_picker_input {
  margin-left: 40px;
  position: relative;
}
div.task_picker_input {
  width: 300px;
  min-width: 300px;
  padding-right: 35px;
}

.counter_circle {
  font-family: 'Inter Regular';
  text-align: center;
  border: 1px solid red;
  padding: 9px 8px 8px 6px;
  border-radius: 30px;
  width: 35px;
  height: 32px;
  font-size: 14px;
  line-height: 14px;
  display: inline;
  font-weight: 600;
  position: absolute;
  right: 30px;
  top: 12px;
}
// .{
//   // position: absolute;
//   background-color: #ffeceb !important;
// }
// .task_input {
//   position: relative;
// }
