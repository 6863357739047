.time_registration_content {
  min-width: calc(100% - 200px);
  padding: 0px;
  flex-wrap: nowrap !important;
  flex-direction: column !important;
  padding: 40px 0px;
  background-color: white;
  padding-bottom: 6em;

  > div {
    margin: 0px 50px;
  }

  > hr {
    margin-top: 25px;
  }
}

.time_registration_grid_container {
  padding: 0px;
  flex-wrap: nowrap !important;
}

.org_list_nav {
  align-self: start;
  position: sticky;
  top: 0px;

  .nav_header {
    margin-top: 10px;
    padding-left: 20px;
  }
}

.list_item_button {
  padding-left: 20px !important ;

  .header_icon {
    width: 25px;
    height: 25px;
    margin-right: 10px;
    border-radius: 4px !important;
  }
}

.org_icon {
  width: 32px;
  height: 32px;
  border-radius: 4px !important;
  margin-right: 10px;
}

.org_header {
  display: flex;

  > h6 {
    align-self: center;
  }
}

.org_header_subtitle > p {
  margin-top: 15px;
}

.org_link {
  margin-left: auto !important;
  align-self: center;
}

.action_buttons_container {
  margin-top: 15px !important;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  > div {
    margin-right: 10px;
    margin-bottom: 10px;
    > button {
      text-transform: none;
    }
  }
  > button {
    text-transform: none;
    margin-bottom: 10px;
    width: 260px;
    white-space: nowrap;
    > svg {
      margin-right: 10px;
    }
  }

  > div > button:nth-child(2),
  > div > button:nth-child(3) {
    padding: 0;
  }
}

.submit_dialog_content {
  margin: 20px;
}

.task_title {
  margin-left: 10px;
}

.task_reg_list {
  margin-left: 10px;
  margin-bottom: 10px;
}

.reg_header_container {
  margin-top: 30px;
  display: flex;
  align-items: center;

  > * {
    margin-right: 5px;
  }

  .clock_icon {
    font-size: 22px;
  }
}

.snackbar_wrapper {
  width: 100%;
}

.all_org_button {
  display: none;

  > div div {
    text-transform: capitalize;
  }
}

.org_button_list {
  height: auto !important;
  padding-top: 0 !important;
}

.org_name {
  text-transform: capitalize;
}

.timesheet_tips {
  position: relative;
}

.timesheet_tips_button {
  position: absolute;
  right: 0;
  padding: 0 !important;
  button {
    line-height: 13px;
  }
  svg,
  p {
    display: inline-block;
    color: #666666;
    font-size: 20px;
  }
  p {
    vertical-align: top;
    padding-left: 5px;
    text-transform: capitalize;
    font-size: 14px;
  }
}

.tips_popover {
  padding: 34px;
  width: 300px;

  p {
    font-size: 14px !important;
  }
}
.tips_popover_header {
  :nth-child(1) {
    font-weight: 600;
    font-size: 19px;
  }
}
.tips_title {
  svg,
  p {
    display: inline-block;
  }
  p {
    vertical-align: top;
    padding-left: 7px;
    font-weight: 600;
  }
}
.tips_desc {
  line-height: 20px;
  padding-top: 16px;
}

.tips_nav_con {
  padding-top: 12px;
  line-height: 10px;
}
.tips_nav_icon,
.tips_nav_text {
  box-shadow: 0px 1px 1px #00000029;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  margin: 4px 8px 4px 0 !important;
  padding: 3px;
  line-height: 16px !important;
}

.tips_nav_text {
  font-size: 12px;
  display: inline-block;
}

@media screen and (max-width: 1365px) and (orientation: landscape) {
  .all_org_button {
    display: block;
  }
  .all_org_timesheet {
    display: none;
  }
  .org_list_nav {
    display: none;
  }
}
@media (max-width: 1365px) {
  .time_registration_content {
    min-width: 100%;
  }
}

@media (max-width: 880px) {
  .time_registration_content > div {
    margin: 0px 25px;
  }

  .reg_header_container {
    display: block;
  }
  .reg_header_time,
  .reg_header_icon {
    display: inline-block;
  }
}

@media screen and (max-width: 740px),
  screen and (max-height: 2000px) and (orientation: portrait) {
  .all_org_button {
    display: block;
  }
  .all_org_timesheet {
    display: none;
  }
  .org_list_nav {
    display: none;
  }
  .snackbar_wrapper {
    width: 100%;
    display: block;
    margin-left: 0;
    > div {
      margin: 0 25px;
      display: block;
    }
  }
}

@media only screen and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3) {
  .all_org_timesheet {
    display: none;
  }
  .org_list_nav {
    display: none;
  }
  .all_org_button {
    display: block;
  }
}

.loadContainer {
  position: relative;
  .box {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.8; /* for demo purpose  */
  }
  .box2 {
    background-color: white;
  }
  .stackTop {
    z-index: 9;
    text-align: center;
  }
  .center {
    top: 40%;
    position: absolute;
    left: 45%;
  }
}
.btnSubmit {
  min-width: 300px !important;
}
