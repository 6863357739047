@import '../../../../../../../../../../assets/ui-kit/variables';

.padTopAdjust {
  margin-top: 14px;
}

.btnTimesheet {
  color: $black !important;
  width: 100% !important;

  &:focus {
    .duration_cell_container {
      border-bottom: 1px solid $gray-dark;
    }
    .expand_icon {
      visibility: visible;
      cursor: pointer;
    }
    .undeline {
      //padding: 0px 30px 2px 30px;
      border-bottom: none;
    }
    .undelinehr {
      border-bottom: none;
      color: $gray-dark;
      //padding: 0px 30px 2px 30px;
    }
  }
}

.duration_cell_container {
  display: flex;
  flex-direction: column;

  width: 100%;

  &:hover {
    border-bottom: 1px solid $gray-dark;

    .undeline {
      border-bottom: none;
    }
    .undelinehr {
      border-bottom: none;
    }
    .expand_icon {
      visibility: visible;
      cursor: pointer;
    }
  }

  .badge {
    width: 8px;
    height: 8px;
    border-radius: 8px;
    align-self: flex-end;
    background-color: #67e36c;
  }

  > .duration_text {
    // z-index: 1;

    width: 100px;
    display: flex;
    align-self: center;
    align-items: center;
    justify-content: center;

    //align-items: flex-end;

    //justify-content: space-between;
    padding-bottom: 5px;
    > .text {
      justify-content: center;
    }
    > svg {
      z-index: 2;
      position: absolute;
      right: 5px;
      visibility: hidden;
    }
    &:hover {
      > svg {
        visibility: visible;
        cursor: pointer;
        background-color: rgba(0, 0, 0, 0.04);
        border-radius: 50%;
      }
    }

    > p {
      //margin-right: -20px;
    }

    > .expand_icon {
      color: gray;
      margin-bottom: 1px;
    }
  }
}

.badgeIcon {
  width: 15px !important;
  height: 15px !important;
  color: $green;
  margin-left: auto;
}

.badgeIcondraft {
  width: 15px !important;
  height: 15px !important;
  color: $blue;
  margin-left: auto;
}

@media only screen and (max-device-width: 480px) {
  .task_menu_button,
  .duration_text > svg {
    visibility: visible !important;
  }
}
@media screen and (max-device-width: 768px) {
  .task_menu_button,
  .duration_text > svg {
    visibility: visible !important;
  }
}

.duration_text_draft {
  padding-top: 15px;
}

.undelinehr {
  border-bottom: 1px solid $gray-dark;
  color: $gray-dark;
}

.undeline {
  border-bottom: 1px solid $gray-dark;
  padding-bottom: 5px;
  //background-color: #67e36c;
  // width: 200px;
  padding-left: 5px;
  padding-right: 5px;
}

.expand_icon {
  visibility: visible;
  cursor: pointer;
}

.pad {
  padding: 10px;
}

.tooltip {
  :hover {
    .duration_cell_container {
      border-bottom: 1px solid $gray-dark;
    }
    .expand_icon {
      visibility: visible;
      cursor: pointer;
    }
    .undeline {
      border-bottom: none;
    }
    .undelinehr {
      border-bottom: none;
      color: $gray-dark;
    }
  }
}

.width100 {
  width: 100%;
}

.fsize12 {
  font-size: 12px !important;
}

.flex {
  display: flex;
  flex-direction: row;
}
