@import '../../../../../assets/ui-kit/_variables';
.teams_proj_container {
  max-width: 800px;
  padding: 0px;
  flex-wrap: nowrap !important;
  flex-direction: column !important;
  background-color: white;
  margin: 27px 27px 0 !important;
  padding: 30px 30px 30px !important;
  border: 1px solid #e0e0e0;
}

.header {
  font-size: 20px !important;
  font-weight: 600 !important;
  color: #303030 !important;
}

.header_desc {
  color: #00000099;
  padding: 5px 0;
  font-size: 14px !important;
}

.close_icon {
  font-size: 15px !important;
  margin-right: 10px;
}

.teams_container {
  margin-top: 25px !important;
}
