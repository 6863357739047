.cancelLabel {
  color: #00000099;
}

.error {
  color: #f44336;
}

.flex {
  display: flex;
  flex-direction: row;
}
