.alertMessage {
  display: inline;
}

.closeIconButton {
  position: absolute !important;
  right: 5px;
  top: 0;
  bottom: 0;
}

.snackbar_container {
  left: 240px !important;
  > div {
      display: flex !important;
      min-width: 400px;
      padding-right: 40px;
  }

  button {
      height: fit-content;
      margin-top: 10px;
  }
}

@media screen and (max-width: 480px) {
  .snackbar_container > div {
      min-width: auto;
  }
}