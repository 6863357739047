.welcome_container {
    height: auto;
    align-items: center;
    position: absolute;
    top: 20%;
    left: 0%;

    > div {
        width: 390px;
        text-align: center;
      }
   
  }

  .meewee_logo_button{
    margin-left: 5px;
  }

  .login_button_container {
    display: flex;
  }