.calendar_text {
    padding-top: 30px;
    padding-left: 20px;
    margin-left: 5px !important;
}

.date_range_display{
    width: 250px;
}


@media screen and (max-width: 479px) {
    .date_range_display{
        width: 200px;
    }    
}