.popover_body {
  height: 200px;

  &.loading {
    opacity: 0.3;
  }

  .desc_text_field {
    margin: 10px 10px;
  }
}

.loading {
  opacity: 0.3;
}

.save_button {
  margin: 20px 10px;
  justify-content: space-between;
  display: flex;
  align-items: center;
}

.loading_spinner {
  position: relative;
  bottom: 150px;
  left: 140px;
  z-index: 1000;
}
.popover_header {
  padding: 10px;
  height: 50px;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;

  .close_icon {
    width: 30px;
    height: 30px;
    > svg {
      margin: 5px;
      font-size: 20px;
      color: white;
    }
  }

  &.draft {
    background-color: #666666;
  }

  &.pending {
    background-color: #00b8d4;
  }

  &.approved {
    background-color: #67e36c;
  }

  > p {
    color: white;
  }
}

.reg_timestamp {
  opacity: 60%;
}

.popover_text {
  display: flex;
  flex-direction: column;
}
