.request_action_container {
  display: block;
}

.request_action {
  display: inline-block;
  right: 0;
  top: 0;
  text-transform: uppercase;
  font-size: 13px;

  > div {
    display: inline-block;
  }
  svg,
  p {
    display: inline-block;
  }
  p {
    font-size: 13px !important;
    font-weight: 500;
  }
  svg {
    font-size: 17px;
    margin: 5px;
  }
}

.request_button_action {
  position: relative;
  width: 200px;
  padding: 5px !important;
  svg {
    color: white;
    border-radius: 18px;
    padding: 2px;
  }
}

.datatable_request_button_action {
  position: relative;
  padding: 5px !important;
  svg {
    color: white;
    border-radius: 18px;
    padding: 2px;
  }
}

.green svg {
  background-color: #67e36c;
}

.red svg {
  background-color: #f44336;
}

.filter_tab {
  padding-top: 20px;
  display: contents;
  button {
    color: #072e36 !important;
  }
  > div {
    background-color: #fff;
  }
}

.snackbar_wrapper {
  margin-left: 20px;
}
.wrapDiv {
  word-wrap: break-word;
  white-space: normal;
  padding-top: 5px;
}

.container {
  width: 100%;
  height: 100%;
  position: relative;
  margin: 0px;
}
.box {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.8; /* for demo purpose  */
}
.stackTop {
  z-index: 9;
  text-align: center;
}
.center {
  top: 40%;
  position: absolute;
  left: 50%;
}

.box1 {
  background: red;
}

.box2 {
  background: white;
}
