.sign_in_button {
  margin-top: 30px;

  > button {
    font-size: 18px;
    font-weight: bold;
  }
}

.login_bg {
  background-image: url('../../../assets/meewee-bg.svg');
  background-color: #f0f8f9;
  background-repeat: repeat-y;
  position: fixed;
  background-size: 100%;
  height: 100vh;
  width: 100%;
  left: 0px;
  height: 100%;
}

.login_container {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  .meewee_logo {
    width: 388px;

    @media (max-width: 1024px) {
      display: none;
    }
  }

  .meewee_logo_large {
    text-align: center;
    padding-bottom: 20px;

    > img {
      width: 70px;
    }

    @media (min-width: 1024px) {
      display: none;
    }
  }

  .signin_form {
    position: relative;
    transform: translatex(140%);

    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: flex;
    min-height: 100vh;
    background-color: white;

    @media (max-width: 1024px) {
      margin-left: 0px;
    }

    .signin_inputbox {
      margin-top: 40px;
    }

    .signin_error_alert {
      margin-top: 30px;
    }

    .socials_container {
      margin-top: 30px;
      margin-bottom: 20px;

      > hr {
        margin-top: 35px;
        margin-bottom: 20px;
      }
    }
  }
}

a.link {
  color: inherit;
  text-decoration: none;
}

a.link:hover {
  text-decoration: underline;
}

.lettermark_logo {
  position: relative;
  font-weight: 200;
  font-family: 'Causten Medium' !important;
  font-size: 23px !important;

  img {
    position: inherit;
    padding-left: 7px;
    width: 96px;
    display: inline-flex;
    top: 3px;
  }
}

.logo_initials {
  position: absolute;
  left: 80px;
  bottom: 120px;
  text-align: left;

  img {
    margin-bottom: 20px;
  }

  p {
    font-family: 'Causten Medium' !important;
    font-size: 23px;
  }
}

.login_form {
  margin: 0;
  align-items: center;
  align-content: center;

  width: 450px;
  text-align: center;
  padding: 50px;
  margin: 0 auto;
}

@media screen and (max-width: 990px) {
  .login_form {
    width: auto;
  }
}

@media only screen and (max-width: 640px) {
  .login_bg,
  .logo_initials {
    display: none;
  }

  .signin_form {
    margin: 0 auto !important;
    padding: 50px 0 !important;

    transform: none !important;
    max-width: fit-content !important;
  }

  .login_form {
    width: 300px;
    padding: 0;
  }
}

.forgetPassWord {
  color: #00b8d4;
  cursor: pointer;
  text-align: right;
}
