.divider {
  border-style: solid !important;
  border-color: #293e42 !important;
}

.mobile_drawer {
  @media screen and (min-width: 1024px) {
    display: none !important ;
  }
}

.desktop_drawer {
  @media only screen and (device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape) {
    display: none;
  }
  @media screen and (max-width: 1023.9px) {
    display: none !important ;
  }
}

.drawer_container {
  .drawer_header {
    height: 63px;
    padding-top: 22px;
    padding-bottom: 28px;
    padding-left: 20px;

    > img {
      width: 35px;
    }

    > span {
      margin-left: 14px;
      color: #ffffff;
    }

    > span.drawer_header_subcontent {
      margin-left: 6px;
    }
  }
}

.arrow {
  &.collapse {
    margin-left: 50%;
  }
  > button {
    color: white;
    padding-left: 20px;
    margin-top: 5px;
    margin-bottom: 5px;

    > div > svg,
    > svg {
      width: 15px;
      height: 15px;

      &.collapse {
        transform: rotate(180deg);
        margin-left: 10px;
      }
    }
  }
}

.drawer_footer {
  width: 100%;
  height: 50px;
}

.list_item_container {
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100% - 113px);
}
