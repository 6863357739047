.signup_grid {
  display: flex;
  align-items: center;
  // justify-content: center;
  height: 100vh;
  // top: 50%;
  // transform: translateY(50%);
  background-color: #ffffff;
  position: relative;
}
.signup_wrapper {
  background: #ffffff;
  width: 440px !important;
  border: 1px solid #d0d0d0;
  box-shadow: 10px 13px 16px #0000000f;
  padding: 35px;
  border-radius: 5px;
  z-index: 9999999;
}
.signup_container {
  display: grid;
  place-items: center;
  align-items: center;
  z-index: 9999999;

  > div {
    width: 360px;
    text-align: center;

    .sign_in_text {
      margin-top: 20px;
    }
  }
}

.socials_container {
  margin-bottom: 20px;
}

.continue_button {
  > button {
    margin-top: 30px;
  }
}

a.sign_in_link {
  color: #0091ea;
  text-decoration: none;
}

a.sign_in_link:hover {
  text-decoration: underline;
}

.signup_error_alert {
  margin-top: 30px;
}

.lettermark_logo {
  position: relative;

  img {
    position: inherit;
    padding-left: 7px;
    width: 96px;
    display: inline-flex;
    top: 3px;
  }
}
.logintr {
  position: absolute;
  top: 0;
  right: 0;
}

.imgLogintr {
  position: absolute;
  bottom: 0;
}
.grid_right {
  display: grid;
  align-items: center;
  z-index: 9999999;
  position: absolute;
  right: 15%;
  width: 450px;
  span {
    margin-bottom: 10px;
  }
  p {
    margin-bottom: 20px;
  }
}
.logoHeading {
  width: 108px;
  vertical-align: middle;
  margin: 0 3px;
}
.email_field {
  padding: 30px 0;
}
.grid_mobile {
  display: none !important;
}

@media screen and (max-width: 605px) {
  .signup_container {
    width: 100% !important;
  }
  .signup_wrapper {
    width: 85% !important;
    margin: 30px auto;
  }
  .grid_mobile span {
    font-size: 24px;
  }
  .logoHeading {
    width: 87px;
  }
}

@media only screen and (max-width: 1024px) {
  .signup_grid {
    display: contents !important;
  }
}

@media screen and (max-width: 1024px) {
  .signup_container {
    width: 605px !important;
    margin: 0 auto;
    transform: translateY(0) !important;
  }
  .signup_display {
    display: none !important;
  }

  .signup_wrapper {
    padding: 25px;
  }

  .signup_grid,
  .grid_right {
    position: relative;
    right: 0;
    display: block;
    height: auto;
    background-color: transparent;
  }
  .grid_parent {
    height: 100vh;
    //display: block !important;
  }
  .grid_mobile {
    display: block !important;
    text-align: left;

    span,
    p {
      text-align: center;
      display: block;
      padding-bottom: 15px;
    }
    p:nth-child(3) {
      margin-bottom: 20px;
    }
  }
  .loginbl,
  .logintr {
    display: none;
  }
}
