button.arrow {
  color: white;
  padding: 0;
  svg {
    color: #ffff;
    font-size: 20px;
  }
}

.nav_sub_list {
  button.arrow {
    margin-right: 3%;
  }
}

.navLink {
  text-decoration: none;
  color: inherit;
  background-color: inherit;
}

div.main_navLink {
  min-height: 48px;
  padding-left: 20px;
  padding-right: 20px;
  position: relative;
  border-left: 3px solid;

  .main_navLink_icon {
    fill: #e0e0e0;
    min-width: 0;
    justify-content: center;
    color: #e0e0e0;
  }
}

.item_selected {
  background-color: rgba(255, 255, 255, 0.2) !important;
  border-left: 3px solid #fff !important;

  p {
    font-family: 'Inter Semibold';
  }

  .main_menu {
    color: #fff;
  }
}
.item_selected_sub {
  background-color: rgba(255, 255, 255, 0.1) !important;
  border-left: 3px solid #fff !important;

  p {
    font-family: 'Inter Semibold';
  }
}

.responsive_arrow {
  display: none !important;
}
.arrow_collapse {
  position: absolute !important;
  right: -8px;
  svg {
    color: #ffff;
    font-size: 20px;
  }
}
@media screen and (max-width: 1024px) {
  .responsive_arrow {
    display: block !important;
    position: absolute;
    z-index: 9999999;
    right: -8px;
    bottom: 0;
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}
