@import '../../../assets/ui-kit/variables';

.appBar {
  background-color: #ffffff !important;
  border-bottom: 3px solid #00b8d4 !important;
  color: black !important;
  height: 66px;
  align-items: flex-end;
  justify-content: center;
  display: flex;
  padding-right: 32px;
  @media (max-width: 1024px) {
    padding-right: 0;
  }
}
.appBar_profile {
  display: flex;
  align-items: center;
}
.appBar_mobile_menu {
  position: absolute !important;
  left: 0;
  @media (min-width: 1024px) {
    display: none !important;
  }
}
.mobile_logo {
  width: 35px;
  margin-left: 10px;
  @media (min-width: 1023px) {
    display: none;
  }
}
.leaveTitleContainer {
  display: inline-flex;
  position: absolute;
  margin-left: 65px;
  width: calc(100% - 65px);
  transition: width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms,
    margin 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
  font: normal normal 600 20px/20px Inter;
  gap: 5px;
  @media (max-width: 1023px) {
    width: calc(100% - 112px);
  }
}

// .icon {
//   color: #00b8d4;
// }

.meeweeText {
  color: #00b8d4;
  margin-top: 5px;
}

.menuTitle {
  margin-top: 5px;
}

.flex {
  display: flex;
  flex-direction: row;
}

.icon {
  color: $primary-main;
  padding-top: 2px;
  width: 25px;
}
