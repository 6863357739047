.weTimesheet_card {
  width: 90%;
  min-width: 1000px;
  padding: 0px;
  background-color: white;
  margin: 0 24px 0 !important;
  padding: 30px 30px 0 !important;
  //position: relative;
  border: 1px solid #e0e0e0;
}

.weTimesheet_header_con {
  padding: 15px;
  > div {
    padding-left: 0 !important;
  }
  display: flex !important;
  flex-direction: row;
  width: 100%;
}

.weTimesheet_name {
  font-size: 20px !important;
  font-weight: 600 !important;
  color: #303030 !important;
}

.weTimesheet_desc {
  color: #00000099;
  padding: 5px 0;
  font-size: 14px !important;
}

.padd {
  padding: 20px;
}

.box {
  position: fixed;
}
