.team_invitation_container {
    position: relative;
    max-width: 700px;
    padding: 0px;
    flex-wrap: nowrap !important;
    flex-direction: column !important;
    background-color: white;
    margin: 47px 27px 0 !important;
    padding: 30px 30px 0 !important;
    border: 1px solid #E0E0E0;

     button.go_back_btn {
        text-transform: capitalize;
        position: absolute;
        top: -38px;
        left: -8px;
        color: #00000099;
    }
}
.invitation_count {
    > span:nth-child(1) {
        vertical-align: middle;
        margin-right: 10px;
    }
}
.team_invitation_card {
    border-top: 1px solid #E0E0E0;
    margin-top: 30px;
}

.team_invitation_header {
    display: flex;
    padding: 25px 10px 10px 10px;
    > div {
        display: inline-flex;
        padding: 0 5px;
    }
}

.team_invitation_avatar {
    display: block !important;

    .team_invitation_name { font-size: 14px; }
    .team_invitation_date {
        font-size: 12px;
        color: #00000099;
    }
}

.team_invitation_message {
    display: block !important;
    margin: 10px;
}

.team_invitation_action {
    margin: 30px 10px;
    button {
        margin-right: 10px;
        width: 75px;
    }
}

.team_invitation_join {
    background-color: #00B8D4 !important;
    box-shadow: 0px 3px 3px #0000001F;
    span {color: white;}
}

.team_invitation_decline {
    span { color: #F44336;}
}