@import '../../../../../../assets/ui-kit/_variables';

.accordion_header_org {
  margin-left: 10px !important;
  font-size: 14px !important;
  font-weight: 600 !important;
}

.menu_icon {
  margin-left: auto !important;
}

.accordion_header {
  align-items: center;
  min-height: unset !important;
  border-bottom: 1px solid $gray-light4 !important;
}

.accordion_details {
  padding: 16px 16px !important;
}

.accordion_manager {
  font-size: 12px !important;
  float: right;
  right: 65px;
  position: absolute;
  color: $gray-dark3;
}
.accordion_role {
  span {
    border-radius: 27px;
    padding: 6px 9px;
    color: $white;
    background-color: $gray-dark4;
    font-size: 12px;
    margin-left: 10px;
  }
}
