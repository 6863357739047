@import '../../../../../../../assets/ui-kit/variables';
.box {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.8; /* for demo purpose  */
}
.stackTop {
  z-index: 9;
  text-align: center;
}
.center {
  top: 40%;
  position: absolute;
  left: 50%;
}

.box2 {
  background: white;
}

.container {
  width: 100%;
  height: 100%;
  position: relative;
  margin: 0px;
  border-radius: 5px;
  border: 1px solid $gray-light;
  margin-bottom: 30px !important;
}

.filter_tab {
  padding-top: 20px;
  display: contents;
  button {
    color: #072e36 !important;
  }
  > div {
    background-color: #fff;
  }
}
.pad {
  padding: 10px 0px 10px 0px;
}

.subtitle2 {
  color: $gray-dark3;
  font-weight: $regular !important;
}

.subtitle1 {
  padding-top: 24px;
  font-weight: $semiBold !important;
}

.toolBarContainer {
  padding: 0px !important;
}

.flex {
  display: flex;
  flex-direction: row;
}

.avatarPad {
  padding: 10px 0px 10px 0px;
}

.flex {
  padding: 10px 0px 10px 0px;
  display: flex;
  flex-direction: row;
}

.btnRight {
  margin-left: auto;
  padding: 5px;
}

.btnColor {
  color: red !important;
}

.pad5 {
  padding: 5px;
}
.letter {
  text-transform: capitalize;
}

.padRight {
  margin-left: auto;
}

.fullwidth {
  width: 100%;
}
