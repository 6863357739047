.header {
  padding-bottom: 30px;
}

.title {
  font-size: 20px !important;
  font-weight: 600 !important;
  color: #303030 !important;
}

.title_desc {
  color: #00000099;
  padding: 5px 0;
  font-size: 14px !important;
}

.back_btn {
  color: #00000099;
  margin-bottom: 20px;
}

.space_bottom {
  padding-bottom: 30px;
}

.confirm_btn {
  float: right;
}

.div_block {
  display: inline-block;
}

.cancel_btn {
  color: gray !important;
}
