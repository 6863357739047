.logout_button {
  position: absolute;
  right: 10px;
}

.info_alert {
  width: 400px;
  position: absolute;
  top: 8%;
  left: 45%;
  z-index: 10000;
}
