.reg_filter {
  margin-top: 30px;
  margin-bottom: 30px;
}

.date_picker {
  width: 180px;
}

.padR10 {
  padding-right: 20px;
}

.padT8 {
  padding-top: 7px;
}

.select {
  width: 180px;
}

.select_employees {
  width: 220px;
}
