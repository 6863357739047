.arc_tr {
  position: fixed;
  background: url(../../../../assets/login-tr-arc.svg);
  display: inline-block;
  width: 229px;
  height: 104px;
  right: 0;
  top: 0;
  z-index: 99999;
}
.arc_bl {
  position: fixed;
  background: url(../../../../assets/login-bl-arc.svg);
  display: inline-block;
  width: 453px;
  height: 174px;
  left: 0;
  bottom: 0;
  z-index: 0;
}
