@import '../../../../../../../../assets/ui-kit/_variables';

.img {
  height: 60px;
  width: 60px;
  border-radius: 15px;
}

.flexContainer {
  display: flex;
  flex-direction: row;
  width: 100% !important;
}

.desc {
  color: #000000de;
  font-size: 14px !important;
}

.content {
  padding: 10px;
  width: 400px !important;
}
