@import '../../../../assets/ui-kit/variables';
.organization_menu_grid_container {
  padding: 0px;
  flex-wrap: nowrap !important;
  position: relative;
  // min-height: 100% !important;
  // height: 100%;
  // height: 100% !important;
}

.org_list_menu {
  .list_item_button {
    padding: 8px 26px;
  }
}

.paper_style {
  z-index: 9999;
}

.org_selection_list {
  z-index: 1;
  transform: translate3d(25px, 25px, 0px) !important;
  z-index: 999999 !important;
  width: 332px;

  > div {
    height: auto !important;
    padding-top: 0;
    width: fit-content;
    z-index: 99999;
  }

  > div > ul {
    width: 350px;
  }

  > div > ul > p {
    padding: 12px 16px !important;
  }

  > div li {
    padding: 12px 9px;
    border-bottom: 1px solid #e3e3e3;
    margin: 0 6px;
  }

  > div li:last-child {
    border-bottom: none !important;
  }
}

.org_list_menu {
  z-index: 99;
  button {
    background-color: white;
    margin-bottom: 15px;
    margin-top: 18px;
  }
  &:hover {
    button {
      background-color: white;
      margin-bottom: 15px;
      margin-top: 18px;
    }
  }

  > div > div {
    position: relative;
    width: 100%;
    display: block;
    box-shadow: none;
  }
}

.org_list_button {
  float: right;
  min-width: 0 !important;
  padding: 6px !important;
  position: absolute !important;
  top: 0;
  right: 0;
}

.org_button_con {
  height: unset;
  display: inline-block;
  text-align: left;
  width: calc(100% - 20px);
  margin-bottom: 20px;
  > div {
    text-transform: initial;
  }
}

.org_select_logo {
  display: inline-flex;
}

.org_select_name {
  display: inline-flex;
  text-transform: capitalize;
}

.org_select_name:nth-of-type(2) {
  display: block;
  width: 200px;
  line-height: 14px;
  overflow-wrap: break-word;
  word-wrap: break-word;
  white-space: pre-wrap;
}

.org_select_name_width {
  width: 285px !important;
  padding-right: 15px !important;
  display: block !important;
  line-height: 27px !important;
}

.org_con_role {
  display: block;
  padding-bottom: 20px;
}

.org_select_role {
  display: inline-block;
  font-size: 12px;
  color: $white;
  background: $linear-lighter;
  padding: 0px 5px 2px 5px;

  border-radius: 11px;
  margin-right: 7px;
  text-transform: capitalize;
  font-weight: 100;
  margin-top: 5px;

  span {
    font-size: 12px;
  }
}

.org_header_icon {
  width: 32px !important;
  height: 32px !important;
  margin-right: 10px;
  border-radius: 4px !important;
}

.org_none {
  display: none;
}

@media screen and (max-height: 660px) {
  .org_list_menu {
    padding-bottom: 66px;
  }
}

.snackbar_wrapper {
  margin-left: 20px;
  z-index: 2000;

  > div {
    position: unset;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    max-height: 100%;
    margin-top: 10px;
  }

  > div > div {
    position: relative;
    display: block;
  }

  > div > div > div {
    display: inline-block;
    line-height: 0;
    margin-top: 0 !important;
    vertical-align: middle;
  }
}

.menu_divider {
  margin: 20px 0px !important;
}

.select_org_container {
  display: flex;
  align-items: center;
}

.role_container {
  margin-top: 5px;
}

.org_select_org {
  display: flex;
  flex-direction: column;
  align-items: flex-start !important;
}

.info_alert {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
  width: -webkit-fill-available;
}

.avatar {
  background-color: $linear;
}

.paper {
  padding-top: 0px !important;
}

.avatarContainer {
  padding-left: 15px;
  background: rgb(0, 136, 163);
  background: linear-gradient(
    313deg,
    rgba(0, 136, 163, 1) 0%,
    rgba(55, 171, 195, 1) 100%
  );
}

.flex {
  display: flex;
  flex-direction: row;
  width: 155px;
}
.arrowColor {
  color: $linear;
}

.divArrowColor {
  margin-left: auto;
  padding-top: 6px;
}

.nameAvatar {
  font-weight: 600 !important;
  text-transform: capitalize !important;
  padding-left: 6px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 90px;
}

.org_select_role_popover {
  display: inline-block;
  font-size: 12px;
  color: $gray-light;
  background: $gray-dark;
  padding: 2px 6px 2px 6px;

  border-radius: 11px;
  margin-right: 7px;
  text-transform: capitalize;
  font-weight: 100;
  margin-top: 5px;

  span {
    font-size: 12px;
  }
}

.name {
  margin: auto;
}
