@import '../../../../../../../assets/ui-kit/_variables';

.img {
  height: 60px;
  width: 60px;
  border-radius: 15px;
}

.flexContainer {
  display: flex;
  flex-direction: row;
  width: 100% !important;
}

.dialog {
  text-align: center !important;
}

.desc {
  color: #000000de;
  font-size: 14px !important;
}

.titleLabel {
  color: #000000de;
  font-size: 16px !important;
  font-weight: $bold !important;
}

.titleLabel1 {
  color: #000000de;
  font-size: 16px !important;
  padding-left: 5px;
}

.container {
  width: 100%;
  border: 1px solid $gray-light;
  margin-bottom: 20px;
}

.flex {
  display: flex;
  flex-direction: row;
}

.avatar {
  color: #00000099;
  font-size: 14px !important;
}

.alignRight {
  margin-left: auto;
  padding-right: 10px;
}

.pad {
  padding: 10px;
}

.row {
  width: 50%;
}

.avatarNameSmall {
  height: 30px;
  width: 30px;
}

.btn {
  width: 100% !important;
}

.btn:hover {
  background-color: $gray-lighter;
  .task_menu_button {
    visibility: visible !important;
  }
}
.btnBlack {
  color: #000;
}

.task_menu_button {
  visibility: hidden !important;
}

.titleLabel2 {
  color: #000000de;
  font-size: 16px !important;
  padding-left: 5px;
}
