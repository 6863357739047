@import '../../../../../../../assets/ui-kit/variables';
.box {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.8; /* for demo purpose  */
}
.stackTop {
  z-index: 9;
  text-align: center;
}
.center {
  top: 40%;
  position: absolute;
  left: 50%;
}

.box2 {
  background: white;
}

.container {
  width: 100%;
  height: 100%;
  position: relative;
  margin: 0px;
  border-radius: 5px;
  border: 1px solid $gray-light;
  margin-bottom: 30px !important;
}

.filter_tab {
  padding-top: 20px;
  display: contents;
  button {
    color: #072e36 !important;
  }
  > div {
    background-color: #fff;
  }
}
.pad {
  padding: 15px 0px 15px 0px;
}

.subtitle2 {
  color: $gray-dark3;
}

.subtitle1 {
  padding-top: 24px;
  font-weight: 600;
}

.toolBarContainer {
  padding: 0px !important;
}

.flex {
  display: flex;
  flex-direction: row;
}
