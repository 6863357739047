@import '../../../../../assets/ui-kit/variables';
.timesheet_reg {
  position: absolute;
  right: 0;
  padding-right: 60px;
  display: inline-flex;
  top: 20px;

  ::after {
    content: '';
    display: inline-block;
    width: 5px;
    height: 5px;
    -moz-border-radius: 7.5px;
    -webkit-border-radius: 7.5px;
    border-radius: 7.5px;
    background-color: $black;
    margin: 5px 10px;
  }

  :last-child::after {
    content: none;
  }
}

.status_black {
  text-transform: capitalize;
  color: $black;
}
.status_blue {
  text-transform: capitalize;
  color: $blue;
}
.status_green {
  text-transform: capitalize;
  color: $green-light;
}
.status_red {
  text-transform: capitalize;
  color: #f44336;
}

.timesheet_approved span,
.status_green {
  color: $green-light;
}

.timesheet_pending span,
.status_blue {
  color: $blue;
}

.timesheet_grid {
  line-height: 30px;
}

.task_name_hyperlink_div {
  display: inline;
  // color: #00b8d4;
  text-decoration: none;
  cursor: pointer;
}

.task_name_hyperlink_div:hover {
  text-decoration: underline;
}

.task_menu_button {
  position: absolute !important;
  right: 0;
}

.menu_icon {
  padding-right: 5px;
}

.view_modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400;
  background-color: $white;
  border: 2px solid #000;
  box-shadow: 24;
}

.view_modal_wrap {
  background-color: rgb(255, 255, 255);
}

.view_modal {
  border: none;
  border-radius: 4px;
  padding: 40px;
  width: 575px;

  h2 {
    border-bottom: 1px solid $gray-light;
    padding: 13px 0;
  }
}

.reg_accordion {
  box-shadow: none !important;
  margin: 13px 0;
  border: 1px solid #e0e0e0;

  ::before {
    content: none;
  }

  .reg_ownerName {
    display: inline-block;
    padding: 10px !important;
    font-weight: 600;
  }
}

.reg_accordion1 {
  border: 1px solid $blue-light;
  border-radius: 3px;
  box-shadow: none !important;
  margin: 13px 0;

  ::before {
    content: none;
  }

  .reg_ownerName {
    display: inline-block;
    padding: 10px !important;
    font-weight: 600;
  }
}

.view_modal_f,
.view_modal_s,
.view_modal_t {
  border-bottom: 1px solid $gray-light;
  padding: 13px 0;
  font-size: 14px !important;
}

.modal_desc {
  margin-bottom: 10px;
  p:nth-child(1) {
    color: $gray-dark3;
    font-size: 12px !important;
  }
  p:nth-child(2) {
    font-size: 14px !important;
    overflow-wrap: break-word;
  }
}

.notes_scroll {
  overflow-y: auto;
  max-height: 120px;
}

.view_btn {
  padding: 30px 0px 0px 0px;
}
.btn {
  text-align: right;
  width: 100%;
}

.view_avatar {
  width: 100%;
  border-bottom: 1px solid $gray-light;
  padding: 13px 0;
}

.avatarName {
  padding: 10px !important;
}

.btnGroup {
  padding: 0px;
  margin: 0px;
  button {
    padding-top: 0px;
    padding-bottom: 0px;
  }
}

.avatar {
  display: inline-block;
}

.snackbar_wrapper > div {
  position: fixed;
  left: 49% !important;
  transform: translate(-50%, 0);
}

.flex {
  display: flex;
  flex-direction: row;
  padding-bottom: 5px;
  padding-left: 10px;
  padding-top: 5px;
}

.padNoteName {
  padding-left: 10px;
}

.noNotes {
  padding-top: 8px;
  color: $gray-dark2;
  font-size: 12px;
}
.task_menu_button_container {
  margin-left: auto;
  padding: 11px 20px;
  height: 40px;
  margin-left: auto;
  margin-right: 1px;
  &:hover {
    background-color: rgba(0, 0, 0, 0.04);
    border-radius: 104px;
  }
}

.task_menu_button {
  visibility: hidden !important;
  color: rgba(0, 0, 0, 0.54);
  margin-right: 20px;
}

.task_container {
  width: 100%;
  justify-content: space-between;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 16px;
  .task_title {
    padding-left: 20px;
    height: auto;
    // display: block;
    width: 300px;
    white-space: normal;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

.timesheet_grid > td {
  background-color: #f8f8f8;
}

.fontSize16 {
  font-size: 16px !important;
}

.fontSize14 {
  font-size: 14px !important;
}

.tooltip_project {
  padding: 10px;
  color: $black;
  font-size: 14px;

  p {
    margin: 0;
    line-height: 14px;
  }

  ul {
    padding: 0 15px;
  }
}
