@import '../../../../assets/ui-kit/variables';
.card {
  display: -webkit-inline-box;
  width: 100%;
  min-width: 600px;
  max-width: 900px;
  padding: 0px;
  flex-wrap: nowrap !important;
  flex-direction: column !important;
  background-color: white;
  height: calc(100vh - 95px);
  min-height: 650px;
  position: relative;
  border: 1px solid #e0e0e0;
  margin: 25px 0px 0px 25px;
  padding: 25px;
  > div {
    display: block;
  }
  > hr {
    margin-top: 25px;
  }
}

.flexContainer {
  display: flex !important;
  flex-direction: row;
  .subtitle2 {
    font-weight: 400;
    color: $gray-dark;
  }
}

.iconKey {
  padding-top: 5px;
  margin-right: 10px;
}

.headerContainer {
  padding-bottom: 20px;
  width: 100%;
  border-bottom: 1px solid #e1dfdd;
}

.container {
  width: 100%;
}

.body {
  padding: 30px 0px;
  width: 100%;
  border-bottom: 1px solid #e1dfdd;
}

.textBox,
.password {
  width: 350px;
}

.bodyPassword {
  padding: 20px 0px;
  width: 100%;
}

.margin {
  margin-right: 10px;
  padding-top: 3px;
}

.lblWeigth {
  font-weight: 600;
  padding-bottom: 10px;
}

.validationList {
  font-size: 12px;
  position: relative;
  font-family: 'Inter Regular';
  color: $gray-dark3 !important;
  padding: 0;
  padding-top: 7px;
  margin-top: 3px;
  line-height: 13px;
  ul {
    margin: 5px !important;
    padding: 0 0 0 13px;
  }

  li {
    list-style-type: none;
    margin-top: 6px;
  }
  svg {
    font-size: 14px;
    line-height: 19px;
    position: absolute;
    left: 0;
  }

  .validated {
    color: $green-light !important;
  }
  .headValidation {
    color: $red-primary;
  }
}

.updatePasswordBtn {
  margin-top: 30px;
}

.oldPasswordTextbox {
  margin-bottom: 10px;
}
