.list_skeleton {
  padding: 20px;
}

.list_skeleton_details {
  text-align: center;
  align-items: center;
  padding: 5px 0;
}

.list_skeleton_variant {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  padding-right: 20px;
}
