.appBar_profile {
  display: flex;
  align-items: center;
  margin-right: 20px;
}

.flexContainer{
  display: flex !important;
  flex-direction: row;
}

.profileName{
  padding: 30px 30px  !important;
  border-bottom: solid 1px #e1dfdd;
  width: 100%;
}

.container{
  width: 100%;
}

.active{
  background-color: "red"

}

.palbl{
  font-weight: normal !important;
}