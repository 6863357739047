@import '../../../../assets/ui-kit/_variables';

.container {
  height: auto;
  align-items: center;
  position: absolute;
  top: 20%;
  left: 0%;
  > div {
    width: 390px;
    text-align: center;

    .text {
      margin-top: 20px;
    }
  }
}



.button {
  > button {
    margin-top: 10px;
  }
}

a.link {
  color: inherit;
  text-decoration: none;
}

a.link:hover {
  text-decoration: underline;
}


.alert {
  margin-top: 30px;
}

.lettermark_logo {
  position: relative;
  font-weight: 600;
  font-family: "Causten Medium" !important;
  font-size: 23px !important;

  img {
    position: inherit;
    padding-left: 7px;
    width: 96px;
    display: inline-flex;
    top: 3px;
 
  }
}
.header{
  width: 650px !important;
  min-width: auto;
}
.subHeader{
  width: 650px !important;
  padding-top: 25px !important;
}

.btnPad{
  padding-top: 25px !important;
}


.headerLabel{
  font-weight: $bold !important;
  font-size: 32px !important;  
}

.logintr{
  display: block;
  margin-left: auto;
}

.imgLogintr{
  padding-top: calc(100vh - 295px);
}

.subLabel{
  font-size: 18px !important;
}

.bgWhite{
  background-color: $white-bg;
}