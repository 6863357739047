@import '../../../../../../../../../assets/ui-kit/variables';

.cellPlaceHolder {
  height: 51px;
}

.task_row {
  .weekend_column {
    background-color: rgba(251, 255, 255, 0.6);
  }

  td,
  th {
    border-left: 1px solid #e3e3e3;
  }

  td:last-child,
  th:last-child {
    border-right: 1px solid #e3e3e3;
  }

  > td {
    height: 52px;
    align-content: center;
    padding: 0px;
  }

  > td:first-child {
    width: 400px;

    @media (max-width: 1023px) {
      width: 200px;
    }

    @media (min-width: 1024px) and (max-width: 1365px) {
      width: 250px;
    }
  }

  > td:not(:first-child):not(:last-child) {
    flex: 0.5;
    min-width: 80px;

    @media (max-width: 1365px) {
      flex: 0.5;
      width: 80px;
    }
  }

  > td:last-child {
    flex: 0.5;
    min-width: 90px;

    @media (max-width: 1365px) {
      flex: 0;
      width: 90px;
    }
  }
}

.data_grid_table {
  width: 100% !important;
}

.weekEnd {
  width: 100%;
}

.tableBorder {
  border-left: 1px solid #e3e3e3;
  border-right: 1px solid #e3e3e3;
}

@media screen and (max-width: 1365px) {
  .tableBorder {
    border-top: 1px solid #e3e3e3;
    width: 1178px !important;
  }
  .tablePadding {
    width: 1328px;
    border-bottom: none !important;
  }
}

@media screen and (max-width: 1023px) {
  .tableBorder {
    width: 1127px !important;
  }
}

.tablePadding {
  padding: 0px !important;
  margin: 0px !important;
}

.addTask {
  padding-left: 0px !important;
}
