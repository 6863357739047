.task_container {
  width: 100%;
  justify-content: space-between;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 16px;
  .task_title {
    padding-left: 45px;
    height: auto;
    width: 300px;
    white-space: normal;

    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

.task_menu_button_container {
  padding: 8px 8px;
  height: 40px;
  &:hover {
    background-color: rgba(0, 0, 0, 0.04);
    border-radius: 70%;
  }
}

.task_menu_button_show {
  color: rgba(0, 0, 0, 0.54) !important;
}

.task_menu_button_hidden {
  visibility: hidden !important;
}

@media only screen and (max-device-width: 480px) {
  .task_menu_button,
  .duration_text > svg {
    visibility: visible !important;
  }
}

@media screen and (max-device-width: 768px) {
  .task_menu_button,
  .duration_text > svg {
    visibility: visible !important;
  }
}

.menu {
  li > svg {
    margin-right: 10px;
  }
}
