.popover_header {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  padding: 10px;
  height: 50px;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  margin-top: -32px;
  width: 349px;
  margin-left: -32px;

  .close_icon {
    width: 30px;
    height: 30px;
    > svg {
      margin: 5px;
      font-size: 20px;
      color: white;
    }
  }

  &.draft {
    background-color: #666666;
  }

  &.pending {
    background-color: #f78e00;
  }
  &.approved {
    background-color: #68c657;
  }
  &.declined {
    background-color: #ea402f;
  }
  &.cancelled {
    background-color: #ea402f;
  }

  > p {
    color: white;
  }
}

.popover_text {
  display: flex;
  flex-direction: column;
  margin-left: 15px;
}

.popover_body {
  height: 200px;

  &.loading {
    opacity: 0.3;
  }

  .duration_text_field {
    width: 150px;
    margin: 10px 10px;
  }

  .desc_text_field {
    margin: 10px 10px;
  }
}

.leave_status {
  color: #00000099;
  padding: 5px 0;
  font-size: 12px !important;
  // text-transform: capitalize;
}

.badge_container {
  margin-top: 10px;
  margin-bottom: 15px;
}
.flexContainer {
  display: flex;
  gap: 5px;
  align-items: center;
}

.pad {
  width: 21px !important;
}
.marginbadge {
  margin-right: 3px;
}

.badge {
  width: 8px;
  height: 8px;
  border-radius: 8px;

  &.remaining {
    background-color: #f2f2f2;
  }
  &.pending {
    background-color: #f78e00;
  }
  &.approved {
    background-color: #68c657;
  }
  &.declined {
    background-color: #ea402f;
  }
  &.entitlement {
    background-color: #ffffff;
  }
  &.cancelled {
    background-color: #ea402f;
  }
}

.leave_date {
  font-size: 16px !important;
  font-weight: 600 !important;
  color: #303030 !important;
  margin-bottom: 8px;
}

.leave_desc {
  font-size: 14px !important;
  font-weight: 600 !important;
  color: #303030 !important;
  margin-bottom: 8px;
}

.leave_days {
  font-size: 13px !important;
  font-weight: 400 !important;
  color: #303030 !important;
  margin-bottom: 8px;
}

.leave_btn {
  margin-top: 30px;
}

.delete_btn {
  margin-right: 5px !important;
}

.edit_btn {
  // color: gray !important;
  // border-color: gray !important;
  margin-right: 5px !important;
}

.title {
  font-size: 14px !important;
  font-weight: 600 !important;
  color: white !important;
  white-space: nowrap;
  width: 260px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.icon_style {
  font-size: 30px;
  margin-right: 5px;
  vertical-align: top;
}

.div_block {
  font-size: 13px !important;
  display: inline-block;
}

.info_icon {
  display: inline-block;
  margin-left: 5px;
  vertical-align: middle;
  vertical-align: -webkit-baseline-middle !important;
  margin-top: -2px;
  font-size: 13px !important;
  font-weight: 400 !important;
}

.tooltip {
  text-align: center;
  font-size: 14px;
  padding: 10px;
  line-height: 1.5em;
  font-weight: 400;
}

.icon_size {
  font-size: 30px !important;
  vertical-align: text-top !important;
}

.date {
  font-weight: bold;
}

.modal_desc {
  color: #00000099;
  padding: 5px 0;
  font-size: 12px !important;
}

.modal_desc_sub {
  margin-top: 10px;
  color: #00000099;
  padding: 5px 0;
  font-size: 14px !important;
}

.input_change_of_plan {
  width: 330px;
}

.status_tooltip {
  text-transform: capitalize;
  display: inline-block;
  padding: 3px;
  font-size: 12px;
  text-align: center;
}

.cancelled_status {
  color: #ea402f;
  padding: 5px 0;
  font-size: 12px !important;
  // text-transform: capitalize;
}

.cancelled_status_container {
  display: flex;
  gap: 5px;
  align-items: center;
  margin-bottom: 10px;
  margin-top: -20px;
}

.vacation {
  margin-left: 5px;
}

.colorRed {
  color: #ea402f;
}
