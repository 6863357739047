@import '../../../../../../../assets/ui-kit/variables';

.flexContainer {
  display: flex;
  flex-direction: row;
  margin-bottom: 50px;
  padding-top: 24px;
}

.row1 {
  width: 350px;
}

.fieldWidth {
  width: 250px;
}

.task {
  color: $gray-dark2;
  font-size: 12px;
}

.taskResult {
  font-size: 16px;
}

.row2 {
  width: 250px;
}

.manage_details_wrapper {
  padding: 0px;
  width: 100%;
  margin-bottom: 40px;
  p {
    font-size: 12px !important;
    padding: 0;
  }
}
