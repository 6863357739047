@import '../../../../../../assets/ui-kit/_variables';

.header_con {
  padding: 15px;
  > div {
    padding-left: 0 !important;
  }
  width: 100%;
}

.name {
  font-size: 20px !important;
  font-weight: 600 !important;
  color: #303030 !important;
}
.desc {
  color: #00000099;
  padding: 5px 0;
  font-size: 14px !important;
}

.invite_card {
  display: -webkit-inline-box;
  max-width: 850px;
  width: 850px;
  padding: 0px;
  flex-wrap: nowrap !important;
  flex-direction: column !important;
  background-color: white;
  margin: 0 27px 0 27px !important;
  padding: 30px 30px 0 !important;
  position: relative;
  border: 1px solid #e0e0e0;

  > div {
    display: inline-block;
  }
  > hr {
    margin-top: 25px;
  }
}

.header_con {
  > div {
    padding-left: 0 !important;
  }
}

@media screen and (max-width: 639px) {
  .invite_card {
    width: 100%;
  }
}

.flexContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding-bottom: 15px;
  border-bottom: 1px solid $gray-light5;
}
.invite_button {
  margin-left: auto;
  display: flex;
  flex-direction: row;
}

.button {
  padding: 6px 35px 6px 35px;
}

.tabs {
  width: 100%;
  padding: 15px;
}

.tab {
  width: 150px;
}
.bck_btn {
  padding: 15px;
  color: $gray-dark3;
  font-size: 12px;
}
.manage_details_wrapper {
  padding: 15px;
  width: 100%;
  margin-bottom: 120px;
  p {
    font-size: 12px !important;
    padding: 0;
  }
}
.manage_details {
  border-top: 1px solid $gray-light5;
}
.manage_sub {
  display: inline-block;
  padding-right: 100px;
  padding-top: 15px;
}

.manage_sub .sub_title {
  font-family: 'Roboto Regular' !important;
  color: $gray-dark3;
  font-size: 12px !important;
}
