.dashboard_main_container {
    background-color: #F5F5F5;
    // min-height: 100% !important;
    height: 100% !important;
}


.dashboard_content_container {
    // min-height: calc(100vh - 66px);
    padding-top: 66px;
    // min-height: 100% !important;
    height: 100% !important;
}

.collapsed_drawer {
    margin-left: 60px;
}

.expanded_drawer {
    margin-left: 221px;
}