@import '../../../../../../../assets/ui-kit/_variables';

.box {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.8; /* for demo purpose  */
}
.stackTop {
  z-index: 9;
  text-align: center;
}
.center {
  top: 40%;
  position: absolute;
  left: 50%;
}

.box2 {
  background: white;
}

.container {
  width: 100%;
  height: 100%;
  position: relative;
  margin: 0px;
  border-radius: 5px;
  border: 1px solid $gray-light;
  margin-bottom: 10px !important;
}

.filter_tab {
  padding-top: 20px;
  display: contents;
  button {
    color: #072e36 !important;
  }
  > div {
    background-color: #fff;
  }
}
.pad {
  padding: 15px 0px 15px 0px;
}

.menu_icon {
  padding-right: 5px;
}

.task_menu_button_container {
  margin-left: auto;
  padding: 0px;
  height: 40px;
  margin-left: auto;
  margin-right: 1px;

  &:hover {
    background-color: rgba(0, 0, 0, 0.04);
    border-radius: 104px;
  }
}

.task_menu_button {
  visibility: hidden !important;
}

.toolBarContainer {
  padding: 0px !important;
}

.project_name_hyperlink_div {
  display: inline;
  text-decoration: none;
  cursor: pointer;
}

.project_name_hyperlink_div:hover {
  color: $blue-light;
  text-decoration: underline;
}
