.reg_table_header {
  margin-top: 30px;
  > table {
    @media (min-width: 1262px) and (max-width: 1365px) {
      width: fit-content !important;
    }
  }

  thead > tr {
    display: flex;

    &:last-child {
      th {
        flex-direction: row;
      }
      th:first-child {
        justify-self: flex-start;
      }
      th:not(:first-child) {
        padding: 0px;
        justify-content: center;
      }
    }

    th {
      border-left: 1px solid #e3e3e3;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    th:last-child {
      border-right: 1px solid #e3e3e3;
    }

    > th {
      background-color: #f0f0f0;
    }

    > th:first-child {
      width: 400px;

      @media (max-width: 1023px) {
        width: 200px;
      }

      @media (min-width: 1024px) and (max-width: 1365px) {
        width: 250px;
      }
    }
    > th:not(:first-child):not(:last-child) {
      flex: 0.5;
      min-width: 116px;
      @media (max-width: 1365px) {
        flex: 0;
        width: 116px;
      }
    }
    > th:last-child {
      flex: 0.5;
      min-width: 116px;
      @media (max-width: 1365px) {
        flex: 0;
        width: 116px;
      }
    }
  }

  tbody {
    > tr {
      display: flex;
    }

    &.allOrg_table_body > tr.org_row {
      > td {
        border-left: 1px solid #0000000f;
        border-bottom-color: #0000000f;
        display: flex;
        flex-direction: row;
        align-items: center;
        background-color: #34575f;
      }

      > td:first-child {
        padding: 12px 16px;

        width: 400px;

        @media (max-width: 1023px) {
          width: 200px;
        }

        @media (min-width: 1024px) and (max-width: 1365px) {
          width: 250px;
        }
      }

      > td:not(:first-child):not(:last-child) {
        justify-content: center;
        padding: 12px 0px;
        flex: 0.5;
        min-width: 116px;

        @media (max-width: 1365px) {
          flex: 0;
          width: 116px;
        }
      }

      > td:last-child {
        justify-content: center;
        padding: 12px 0px;
        flex: 0.5;
        min-width: 116px;
        border-right: 1px solid #e3e3e3;

        @media (max-width: 1365px) {
          flex: 0;
          width: 116px;
        }
      }
    }
  }
}

.footer {
  border-left: 1px solid #e3e3e3;
  border-right: 1px solid #e3e3e3;
  background-color: #f8f8f8;
}

.today {
  background-color: #00b8d4;
  width: 25px;
  border-radius: 30px;
  color: white;
}

.header_icon {
  width: 25px !important;
  height: 25px !important;
  margin-right: 10px;
  border-radius: 4px !important;
}

.arrows > svg {
  color: white;
}

@media screen and (max-width: 1365px) {
  .footer {
    width: 1178px !important;
  }
  .tabProjectPicker {
    width: 1178px !important;
  }
}

@media screen and (max-width: 1023px) {
  .footer {
    width: 1127px !important;
  }
  .tabProjectPicker {
    width: 1127px !important;
  }
}
