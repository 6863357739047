@font-face {
  font-family: 'Inter Regular';
  src: url(../fonts/Inter/Inter-Regular.ttf);
}

.dashboard-class {
  .mw-dash {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 50px 1fr 25px;
    grid-template-areas: 'header' 'main' 'footer';
    height: 97vh;
    font-family: 'Inter Regular';
  }

  .mw-dash-header {
    grid-area: header;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 8px;
    color: white;
    background-color: darkcyan;
  }

  .mw-dash-main {
    grid-area: main;
  }

  .mw-dash-footer {
    grid-area: footer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 8px;
    background-color: #cfd5d9;
  }

  .mw-dash-main-header {
    display: flex;
    justify-content: space-between;
    margin: 20px;
    padding: 20px;
    height: 150px;
    background-color: darkorange;
    color: white;
  }

  .mw-dash-main-overview {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(265px, 1fr));
    grid-auto-rows: 94px;
    grid-gap: 20px;
    margin: 20px;
  }

  .mw-dash-overview-card {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    background-color: #d3d3;
  }
}
