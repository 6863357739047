.approveLeave_card {
  display: flex;
  flex-direction: row;
  width: 96%;
  min-width: 1000px;
  padding: 0px;
  flex-wrap: nowrap !important;
  flex-direction: column !important;
  background-color: white;
  margin: 0 24px 0 !important;
  padding: 30px 30px 0 !important;
  //position: relative;
  border: 1px solid #e0e0e0;

  > div {
    display: inline-block;
  }
  > hr {
    margin-top: 25px;
  }
}
.approveLeave_header_con {
  padding: 15px;
  > div {
    padding-left: 0 !important;
  }
  display: flex !important;
  flex-direction: row;
  width: 100%;
}
.titleWidth {
  width: 50%;
}
.approveLeave_name {
  font-size: 20px !important;
  font-weight: 600 !important;
  color: #303030 !important;
}

.approveLeave_desc {
  color: #00000099;
  padding: 5px 0;
  font-size: 14px !important;
}

.approveLeave_tab_box {
  padding: 10px 0;
}

.approveLeave_invite_button {
  text-align: right;
  width: 50%;
  margin-left: auto;
  button {
    border-color: #879a9d;
    color: black;
  }
}

.approveLeave_tab_name {
  button {
    font-size: 14px;
    position: relative;
    text-transform: capitalize;
    color: #00000061 !important;
    // padding-right: 36px;
  }
}

button.approveLeave_tab_active {
  padding-right: 18px;
}

.approveLeave_badge {
  position: unset !important;
  span {
    top: 8px;
    right: 5px;
    background-color: #ffab00;
    position: relative;
  }
}

@media screen and (max-width: 740px) {
  .task_menu_button {
    visibility: visible !important;
  }
}

@media screen and (max-width: 639px) {
  .approveLeave_invite_card {
    width: 100%;
  }
}
.boxLinear {
  top: -5px;
}
