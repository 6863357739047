.vacation_type_title {
  color: #000000de;
  font: normal normal 600 14px/18px Inter;
  text-align: left;
  opacity: 1;
  margin: 20px 0 5px 20px;
}
.leave_type_card_container {
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 0px 45px;

  .leave_status_grp {
    display: flex;
    flex: 30%;
    justify-content: space-between;
    margin-left: 20px;
  }
}
.status_container {
  display: flex;
  gap: 30px;
}

.entitlement_icon {
  margin-bottom: -5px;
}

.leave_status_title {
  display: flex;
  flex-direction: column;
  gap: 10px;
  font: normal normal normal 12px/15px Inter;
  color: #00000099;
  opacity: 1;
}
.dataLI {
  color: #404040;
  font: normal normal 600 24px/18px Inter;
  display: flex;
  justify-content: center;
}
.badge_container {
  display: flex;
  gap: 5px;
  align-items: center;
}

.badge {
  width: 8px;
  height: 8px;
  border-radius: 8px;

  &.remaining {
    background-color: #f2f2f2;
  }
  &.pending {
    background-color: #ffc81a;
  }
  &.approved {
    background-color: #67e36c;
  }
  &.entitlement {
    background-color: #ffffff;
  }
}

.report_button {
  width: 190px;
}

.request_button {
  margin-right: 20px !important;
}
