.email_container {
  height: auto;
  align-items: center;
  position: absolute;
  top: 20%;
  left: 0%;
  > div {
    width: auto;
    text-align: center;

    .sign_in_text {
      margin-top: 20px;
    }
  }

  .email_footer {
    width: 25%;
  }
}

a.sign_in_link {
  color: inherit;
  text-decoration: none;
}

a.sign_in_link:hover {
  text-decoration: underline;
}
