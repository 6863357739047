@import '../../../../../../../assets/ui-kit/variables';

.flexContainer {
  display: flex;
  flex-direction: row;
}

.row1 {
  width: 350px;
}

.fieldWidth {
  width: 280px;
}

.task {
  color: $gray-dark2;
  font-size: 12px;
}

.taskResult {
  font-size: 16px;
}

.row2 {
  width: 250px;
}
