.card_skeleton {
  padding: 20px;
}

.card_skeleton_details {
  text-align: center;
}

.card_skeleton_variant {
  margin: 0 auto;
  margin-bottom: 10px;
}
.card_skeleton_middle {
  display: flex;
  justify-content: center;
  align-items: center;
}
