@import '../../../../../../../../../assets/ui-kit/variables';

%enter_hours {
  padding: 0;
  margin: 7px 0 10px;
  font-size: 12px !important;
  font-family: 'Inter Regular';
}

.enter_hours_unfocused {
  @extend %enter_hours;
  color: $gray-dark4;
}

.enter_hours_focused {
  @extend %enter_hours;
  color: $primary-main;
}

.enter_hours_disabled_withLabel {
  @extend %enter_hours;
  color: #9e9e9e;
}
.withLabel_wrapper {
  position: relative;
  margin-left: 10px;
  margin-bottom: 20px;
}

.time_wrapper {
  transition: 0.2s ease;
  align-items: center;
  justify-content: center;
  input {
    padding: 0px 6px;
  }
}

.spacer {
  height: 15px;
}

%time_display {
  position: absolute;
  z-index: 1;
  width: 150px;
  height: auto;
  > p {
    display: flex;
    align-items: center;
    justify-content: center;
    height: auto;
    width: 150px;
  }
}
.time_display_unfocused {
  @extend %time_display;
  border-bottom: 1px solid $gray-dark4;
}

.time_display_focused {
  @extend %time_display;
  border-bottom: 2px solid $primary-main;
}

.time_display_disabled {
  @extend %time_display;
  color: #9e9e9e;
  border-bottom: 1px dotted #9e9e9e;
}

.time_input {
  position: absolute;
  top: 0;
  left: 0;
  width: 150px;
  height: auto;
  align-items: center;
  justify-content: center;

  color: transparent;
  z-index: 99999999999999;
  mix-blend-mode: color;
}

.time_wrapper_noLabel {
  transition: 0.2s ease;
  align-items: center;
  justify-content: center;
  height: 50px;
}
.time_display_noLabel {
  z-index: 1;
  position: absolute;
  align-items: center;
  justify-content: center;
  display: flex;
  left: 0;
  right: 0;
  > p {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    width: 150px;
  }
}
.time_input_noLabel {
  align-items: center;
  justify-content: center;
  color: transparent;
  z-index: 99999999999999;
  padding: 0;
  mix-blend-mode: overlay;
}
