.org_invite_card {
  display: -webkit-inline-box;
  max-width: 800px;
  padding: 0px;
  flex-wrap: nowrap !important;
  flex-direction: column !important;
  background-color: white;
  margin: 27px 27px 0 !important;
  padding: 30px 30px 0 !important;
  position: relative;
  border: 1px solid #e0e0e0;

  > div {
    display: inline-block;
  }
  > hr {
    margin-top: 25px;
  }
}
.org_header_con {
  padding: 15px;
  > div {
    padding-left: 0 !important;
  }
}
.org_name {
  font-size: 20px !important;
  font-weight: 600 !important;
  color: #303030 !important;
}

.org_desc {
  color: #00000099;
  padding: 5px 0;
  font-size: 14px !important;
}

.org_tab_box {
  padding: 10px 0;
}

.org_tab_panel {
  > div {
    padding: 0;
  }
}

.org_invite_button {
  button {
    border-color: #879a9d;
    color: black;
    position: absolute;
    right: 30px;
    top: 40px;
  }
}

.org_tab_name {
  button {
    font-size: 14px;
    position: relative;
    text-transform: capitalize;
    color: #00000061 !important;
    padding-right: 36px;
  }
}

button.org_tab_active {
  padding-right: 18px;
}

.org_badge {
  position: unset !important;
  span {
    top: 24px;
    right: 22px;
    background-color: #ffab00;
  }
}

.invite_panel_close {
  position: absolute !important;
  right: 20px;
  top: 20px;
  color: #00000099 !important;

  &:hover,
  &:focus,
  &:active {
    background-color: transparent !important;
  }
}

.invite_toggle {
  padding: 30px 35px;
  margin-top: 20px;
  width: auto;

  > div {
    margin: 8px 0;
  }
}
.invite_toggle_title {
  width: auto;
  font-size: 16px !important;
  font-weight: bold !important;
  padding-bottom: 5px;
}
.invite_toggle_desc {
  color: #00000099;
  font-size: 14px !important;
  padding-bottom: 40px;
}

// .org_select_form {
//     color: #00000099 !important;
// }

.org_panel_button {
  padding: 30px 0;
  align-content: space-between;
  button {
    text-transform: uppercase;
  }
}

.org_send {
  color: white;
  > button {
    height: 36px;
    width: 180px;
  }
}

.org_cancel {
  > button {
    color: #000000de !important;
    height: 36px;
    width: 100px;
  }
}

.active_members_number {
  padding: 0 20px 20px 0;
  p {
    font-weight: 600;
  }
}

.filter_tab {
  padding-top: 20px;
  display: contents;
  button {
    color: #072e36 !important;
  }
  > div {
    background-color: #fff;
  }
}

.request_action_container {
  display: block;
}

.request_action {
  position: absolute;
  display: inline-block;
  right: 0;
  top: 0;
  text-transform: uppercase;
  font-size: 13px;

  > div {
    display: inline-block;
  }
  svg,
  p {
    display: inline-block;
  }
  p {
    font-size: 13px !important;
    font-weight: 500;
  }
  svg {
    font-size: 17px;
    margin: 5px;
  }
}

.request_button_action {
  position: relative;
  width: 140px;
  padding: 5px !important;
  svg {
    color: white;
    border-radius: 18px;
    padding: 2px;
  }
}

.green svg {
  background-color: #67e36c;
}

.red svg {
  background-color: #f44336;
}

.request_dec_icon {
  svg {
    font-size: 17px !important;
    color: white;
    border-radius: 18px;
    background-color: #f44336;
  }
}

.request_approve_icon {
  svg {
    font-size: 17px !important;
    color: white;
    border-radius: 18px;
    background-color: #67e36c;
  }
}

.task_menu_button {
  visibility: hidden !important;
  color: rgba(0, 0, 0, 0.54);
}

.menu_member_icon {
  position: absolute !important;
  right: 10px;
}

.menu_cell_icon svg {
  font-size: 20px;
  margin-right: 7px;
}

.member_avatar {
  width: 24px !important;
  height: 24px !important;
  margin-right: 5px;
  font-size: 14px !important;
}

@media screen and (max-width: 740px) {
  .task_menu_button {
    visibility: visible !important;
  }
}

@media screen and (max-width: 639px) {
  .org_invite_card {
    width: 100%;
  }
}
.btnsend {
  width: 200px !important;
}
