.loader_container {
  height: auto;
  position: absolute;
  top: 30%;
  left: 0%;
}

.progress_bar_container {
  display: inline-flex;
  position: relative;

  .meewee_icon {
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
