.leave_types_card_container {
  display: flex;
  flex-direction: column;
  gap: 27px;
  margin-left: 40px;
  margin-right: 40px;
  padding-bottom: 70px;
  padding-right: 390px;
}
.request_leave_container {
  max-width: 800px;
  padding: 0px;
  flex-wrap: nowrap !important;
  flex-direction: column !important;
  background-color: white;
  margin: 0 27px 0 !important;
  padding: 30px 30px 30px !important;
  border: 1px solid #e0e0e0;
}
.header {
  padding-bottom: 30px;
}
.title {
  font-size: 22px !important;
  font-weight: 600 !important;
  color: #303030 !important;
}
.title_desc {
  color: #00000099;
  padding: 5px 0;
  font-size: 14px !important;
}
.request_leave_header {
  margin-left: 8px;
}
.back_btn {
  color: #00000099;
  margin-bottom: 20px;
}
.back_btn {
  text-transform: capitalize !important;
}
.space_bottom {
  padding-bottom: 30px;
}
.input_reason {
  width: 500px;
}
.total_days {
  margin-top: 10px;
}
.secondary_button {
  display: inline-block;
  margin-left: 20px;
  button {
    border-color: #879a9d;
    color: black;
  }
}
.date_picker {
  width: 240px;
}
.confirm_btn {
  float: right;
}
.div_block {
  display: inline-block;
}
.info_icon {
  display: inline-block;
  margin-left: 5px;
  vertical-align: middle;
}
.tooltip {
  text-align: center;
  font-size: 14px;
  padding: 10px;
  line-height: 1.5em;
  font-weight: 400;
}
.icon_size {
  font-size: 30px !important;
  vertical-align: text-top !important;
}

.leave_header_subtitle > p {
  margin-top: 15px;
}
.leave_header {
  font-size: 20px !important;
  font-weight: 600 !important;
  color: #303030 !important;
}
.leave_card {
  display: -webkit-inline-box;
  width: 96%;
  padding: 0px;
  flex-wrap: nowrap !important;
  flex-direction: column !important;
  background-color: white;
  margin: 24px 24px 0 !important;
  padding: 30px 30px 0 !important;
  position: relative;
  border: 1px solid #e0e0e0;
  > div {
    display: inline-block;
  }
  > hr {
    margin-top: 25px;
  }
}
.leave_header_con {
  > div {
    padding-left: 0 !important;
  }
}
.leave_name {
  font-size: 20px !important;
  font-weight: 600 !important;
  color: #303030 !important;
  width: 550px;
}
.leave_desc {
  color: #00000099;
  padding: 5px 0;
  font-size: 14px !important;
}
.leave_tab_box {
  padding: 10px 0;
}
.leave_header_container {
  padding: 30px 0px 0px 0px !important;
}

.right_panel {
  height: 100%;
  display: flex;
  position: fixed;
  top: 0px;
  right: 0;
  padding: 60px 30px 0px 10px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 2px #00000059;
  opacity: 1;
}
.cont {
  // display: flex;
  justify-content: center;
  text-align: left;
  width: 350px;
  padding: 10px;
}
.divider {
  width: 85%;
  display: flex;
  justify-content: center;
  z-index: 9999;
  position: absolute;
  bottom: 250px;
}

.alert_icon {
  color: #621b16;
}

.alert_style {
  color: #621b16;
  background-color: #fdeceb;
  margin-bottom: 20px;
  font-size: 14px;
  width: 500px;
}

.desc_icon {
  margin: auto;
  width: 50%;
  padding: 10px;
}

.desc_container {
  margin-top: 5px !important;
  margin-bottom: 10px;
  margin-left: -40px !important;
}

.desc {
  font-size: 12px;
}

.date {
  font-weight: bold;
}

.collision_desc {
  font-weight: 600;
}

.status_tooltip {
  padding: 10px;
}

.snackbar_wrapper {
  width: 100%;
}

.select_container_default {
  margin-top: -3px !important;
}

.year_select {
  font-size: 20px !important;
  font-weight: 600 !important;
  color: #303030 !important;
}
