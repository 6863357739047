.circle {
  display: flex;
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

// * {
//   box-sizing: border-box;
// }

.column {
  float: left;
  width: 33.33%;
  padding: 50px;
}

.row:after {
  display: table;
  clear: both;
}

.divTable {
  display: table;
  width: 100%;
}
.divTableRow {
  display: table-row;
  height: 50px;
  margin: 10px;
}

.divTableRow div {
  display: table-cell;
}

.divTableBody {
  display: table-row-group;
}

.divTableContainer {
  margin: 10px;
  padding-top: 20px;
}

.name {
  vertical-align: middle;
}
