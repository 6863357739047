@import '../../../../../../assets/ui-kit/_variables';

.name {
  font-size: 20px !important;
  font-weight: 600 !important;
  color: #303030 !important;
}

.desc {
  color: #000000de;
  font-size: 14px !important;
}

.dataValue {
  color: #000000de;
  font-size: 16px !important;
}
.orgTitle {
  color: #00000099;
  font-size: 14px !important;
}

.invite_card {
  width: calc(100% - 20px);
  background-color: white;
  margin: 0px 0 0 27px !important;
  padding: 30px 30px 0 !important;
  min-width: 800px;
  max-width: 1000px;

  border: 1px solid #e0e0e0;
}

@media screen and (max-width: 639px) {
  .invite_card {
    width: 100%;
  }
}

.flexContainer {
  display: flex;
  flex-direction: row;
  width: 100% !important;
}

.flex {
  display: flex;
  flex-direction: row;
}
._button {
  margin-left: auto;
  display: flex;
  flex-direction: row;
}

.button {
  padding: 6px 35px 6px 35px;
}

.pictureAvatar {
  height: 57px !important;
  width: 57px !important;
}

.avatarOrganization {
  height: 60px;
  width: 60px;
}

.avatarName {
  height: 40px;
  width: 40px;
}

.titleWidth {
  width: 250px;
  min-width: 250px;
  margin-right: 80px;
}
.titleWidth300 {
  width: 300px;
  min-width: 300px;
  margin-right: 80px;
}

.infoValue {
  width: 300px;
}

.alignCenter {
  text-align: center;
}

.w100 {
  width: 100% !important;
}
.w50 {
  width: 50% !important;
}

.titleLabel {
  color: #000000de;
  font-size: 16px !important;
  font-weight: $bold !important;
}

.btnBack {
  color: $gray-dark !important;
  margin: 10px 0 10px 25px !important;
}
