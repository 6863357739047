@import '../../../../assets/ui-kit/variables';
.signup_form_container {
  height: auto;
  align-items: center;
  position: absolute;
  top: 5%;
  left: 0%;
  padding: 30px;
  > div {
    width: 390px;
    text-align: center;
  }

  .subtitle {
    margin-top: 20px;
    margin-bottom: 50px;
  }
}

.continue_button {
  > button {
    margin-top: 30px;
  }
}

.signup_error_alert {
  margin-top: 30px;
}

.signup_name_form {
  display: flex;
  justify-content: center;
  margin-top: 15px;

  > div {
    display: inline-block;
    width: 100%;
  }

  > div:nth-child(1) {
    padding-right: 20px;
  }
}

.validationList {
  font-size: 12px;
  position: relative;
  font-family: 'Inter Regular';
  color: $gray-dark3 !important;
  padding: 0;
  padding-top: 7px;
  line-height: 13px;
  text-align: left;
  ul {
    margin: 5px !important;
    padding: 0 0 0 13px;
  }

  li {
    list-style-type: none;
    margin-top: 6px;
  }
  svg {
    font-size: 14px;
    line-height: 19px;
    position: absolute;
    left: 0;
  }

  .validated {
    color: $green-light !important;
  }
  .headValidation {
    color: $red-primary;
  }
}
