@import '../../../../../../../assets/ui-kit/_variables';

.img {
  height: 60px;
  width: 60px;
  border-radius: 15px;
}

.flexContainer {
  display: flex;
  flex-direction: row;
  width: 100% !important;
}

.dialog {
  text-align: center !important;
}

.desc {
  color: #000000de;
  font-size: 14px !important;
}
