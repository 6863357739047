.header_con {
  width: 100% !important;
  padding: 15px;
  > div {
    padding-left: 0 !important;
  }

  background-color: red;
}

.name {
  font-size: 20px !important;
  font-weight: 600 !important;
  color: #303030 !important;
}

.desc {
  color: #00000099;
  padding: 5px 0;
  font-size: 14px !important;
}

.invite_card {
  width: calc(100% - 20px);
  background-color: white;
  margin: 27px 0 0 27px !important;
  padding: 30px 30px 0 !important;

  border: 1px solid #e0e0e0;
}

.header_con {
  padding: 15px;
  > div {
    padding-left: 0 !important;
  }
}

@media screen and (max-width: 639px) {
  .invite_card {
    width: 100%;
  }
}

.flexContainer {
  display: flex;
  flex-direction: row;
  width: 100% !important;
}
.invite_button {
  margin-left: auto;
  display: flex;
  flex-direction: row;
}

.button {
  padding: 6px 35px 6px 35px;
}
