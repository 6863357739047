.drop_down_container {
  display: flex;
  justify-content: space-between;
  padding: 20px 0;
}

.drop_down {
  display: flex;
  text-align: left;
  font: normal normal normal 14px/18px Inter;
  letter-spacing: 0px;
  color: #1e242c;
  opacity: 1;
}

.leave_history_title {
  font-size: 14px !important;
  font-weight: 600 !important;
  color: #303030 !important;
}

.leave_history_desc {
  color: #00000099;
  padding: 5px 0;
  font-size: 12px !important;
}

.details_container {
  margin-top: 4px;
  margin-left: -13px;
}
.desc {
  text-align: left;
  font: normal normal normal 14px/14px Inter;
  letter-spacing: 0.01px;
  color: #00000099;
  opacity: 1;
  white-space: nowrap;
  width: 170px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.desc_declined {
  text-align: left;
  font: normal normal normal 14px/14px Inter;
  letter-spacing: 0.01px;
  color: #00000099;
  opacity: 1;
  text-decoration: line-through;
  white-space: nowrap;
  width: 170px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.grid_row {
  margin-top: 10px;
}

.grid {
  align-items: center;
  // padding-bottom: 10px;
  display: flex;
  flex-direction: column;
}
.summary {
  display: flex;
  align-items: center;
}
.divider {
  position: absolute;
  right: 0;
  left: 0;
  bottom: 210px;
}
.bottom_right_panel_container {
  position: fixed;
  bottom: 0px;
  background-color: white;
  margin-bottom: 60px;
  width: 330px;
}

.draft_heading {
  text-align: left;
  font: normal normal 600 14px/17px Inter;
  letter-spacing: 0px;
  color: #000000de;
  opacity: 1;
  padding-top: 20px;
  font-size: 14px !important;
  font-weight: 500 !important;
}
.draft_desc {
  text-align: left;
  font: normal normal normal 12px/15px Inter;
  letter-spacing: 0px;
  color: #00000099;
  opacity: 1;
  display: flex;
  margin-top: 6px;
}
.draft {
  display: flex;
  align-items: center;
  gap: 3px;
}

.status_select {
  width: 110px !important;
  padding-right: 20px !important;
}

.no_data {
  text-align: center;
  font: normal normal normal 14px/14px Inter;
  letter-spacing: 0.01px;
  color: #00000099;
  opacity: 1;
  margin-top: 30px;
}
.details_container {
  margin-top: 4px;
  margin-left: -13px;
  overflow-y: scroll;
  height: 520px;
  // min-height: 100vh;
}
.draft_summary {
  height: 103px;
  // margin-left: -13px;
  // box-sizing: content-box;
  margin-top: 4px;
  margin-left: -13px;
  overflow-y: scroll;
  overflow: auto;
}

// .detail_container {
//   overflow: hidden;
//   ::-webkit-scrollbar {
//     display: none;
//   }
// }
.draft_description {
  margin-left: 13px;
  text-align: left;
  font: normal normal normal 14px/14px Inter;
  letter-spacing: 0.01px;
  color: #00000099;
  opacity: 1;
}
.date {
  text-align: left;
  font: normal normal normal 14px/14px Inter;
  letter-spacing: 0.01px;
  color: black;
  opacity: 1;
}
.status_tooltip {
  text-transform: capitalize;
  display: inline-block;
  padding: 5px;
  font-size: 12px;
  margin-right: -5px;
}
.leave_type_tooltip {
  text-transform: capitalize;
  display: inline-block;
  padding: 5px;
  font-size: 12px;
}
.top_right_panel_container {
  position: fixed;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
}

.main_panel {
  // width: 800px;
  margin-left: auto;
  margin-right: auto;
  margin-top: -20px;
}
.main_panel_top,
.main_panel_bottom {
  position: absolute;
  top: 100px;
  height: 100px;
}

.main_panel_bottom {
  top: auto;
  bottom: 10px;
  height: 190px;
  overflow-y: auto;
  width: 340px;
}

.main_panel_content {
  position: fixed;
  top: 185px;
  margin-left: auto;
  margin-right: auto;
  bottom: 225px;
  overflow: auto;
  background: #fff;
  // width: 800px;
  width: 340px;
}

.panel_content_bottom {
  margin-left: auto;
  margin-right: auto;
}

.panel_content {
  margin: 15px;
  margin-top: -10px;
}

.details_list {
  margin-left: -15px;
  margin-right: 10px;
}

.select_container_default {
  width: 95px;
  margin-right: 10px !important;
}

.select_container_width {
  width: 120px;
  margin-right: 10px !important;
}

.top_panel_content {
  margin: 15px;
  margin-top: -10px;
}

.main_panel_onscroll {
  margin-left: auto;
  margin-right: auto;
  margin-top: -30px;
}

.main_panel_content_onscroll {
  position: fixed;
  top: 135px;
  margin-left: auto;
  margin-right: auto;
  bottom: 280px;
  overflow: auto;
  background: #fff;
  // width: 800px;
  width: 340px;
}

.select_container {
  width: 350px;
}

.badge {
  top: -10px;
  right: -22px;
}
.badge1 {
  position: absolute !important;
  top: 1px;
  left: 12px;
}
.badge2 {
  top: -9px;
  left: -3px;
}
.badgeIcon {
  transform: scale(0.5);
  color: red;
}
.badgeIcondiv {
  top: -10px !important;
  left: -22px !important;
}

.text {
  font-size: 14px !important;
}

.cancel_tooltip {
  display: inline-block;
  padding: 5px;
  font-size: 12px;
  margin-right: -5px;
}

.snackbar_wrapper > div {
  position: fixed;
  left: 49% !important;
  transform: translate(-50%, 0);
}

.badgeWidth {
  width: 44px;
}

.healM5 {
  position: relative !important;
  margin-right: 20px;
}
