@import '../../../assets/ui-kit/variables';
.empty_state_wrapper {
  position: relative;
  display: block;
  margin: 0 auto;
  padding-top: 50px;
  width: 450px;
  border-bottom: 2px solid $gray-light;
}
.empty_state_text {
  margin: 0 auto;
  text-align: center;
  width: 500px;
  margin-top: 30px;
}
.empty_state_details {
  padding: 20px 100px;
}
