.edit_cell_container {
  position: relative;
  display: flex;
  justify-content: center;
}

.input_container {
  background-color: rgb(225, 12, 12);
  position: relative;
  z-index: 1;
}

.duration_input_field > div > fieldset {
  border: none !important;
}

.small_loading_spinner {
  position: absolute;
  z-index: 2;
  text-align: center;
  bottom: 15px;
}
