@import '../../../../../../../../assets/ui-kit/variables';

.project_row {
  .weekend_column {
    background-color: rgba(230, 232, 232, 0.6);
  }

  td,
  th {
    border-left: 1px solid #e3e3e3;
  }

  td:last-child,
  th:last-child {
    border-right: 1px solid #e3e3e3;
  }

  > td {
    background-color: #f8f8f8;
  }

  > td:first-child {
    width: 400px;

    @media (max-width: 1023px) {
      width: 200px;
    }

    @media (min-width: 1024px) and (max-width: 1365px) {
      width: 250px;
    }
  }

  > td:not(:first-child):not(:last-child) {
    flex: 0.5;
    min-width: 116px;

    @media (max-width: 1365px) {
      flex: 0;
      width: 116px;
    }
  }

  > td:last-child {
    flex: 0.5;
    min-width: 116px;

    @media (max-width: 1365px) {
      flex: 0;
      width: 116px;
    }
  }
}

.duration_cell {
  text-align: center;
}

.task_menu_button {
  visibility: hidden !important;
  color: rgba(0, 0, 0, 0.54);
}

.menu {
  li > svg {
    margin-right: 10px;
  }
}

.project_container {
  display: flex !important;
  flex-direction: row;
  align-items: center;

  .menu_icon {
    margin-left: auto;
  }
}

.delete_dialog_content {
  margin: 20px;

  > div {
    display: block;
  }

  > div > div {
    display: inline;
  }

  > div > div > h6 {
    display: inline-block;
  }
}

.loading {
  opacity: 0.3;
}

.delete_task_info {
  margin-top: 20px !important;
}

.loading {
  opacity: 0.3;
}

.project_row_totals {
  display: flex !important;
  justify-content: center;
  align-items: center;

  padding: 0px !important;
}

@media only screen and (max-device-width: 480px) {
  .task_menu_button,
  .duration_text > svg {
    visibility: visible !important;
  }
}

@media screen and (max-device-width: 768px) {
  .task_menu_button,
  .duration_text > svg {
    visibility: visible !important;
  }
}

.dataGrid {
  width: 100%;
}

// @media only screen and (max-device-width: 1359px) {
//   .dataGrid {
//     width: calc(100% - 200px);
//   }
// }

.arrow {
  &:before {
    border: '1px solid #E6E8ED';
  }
  color: white;
}
