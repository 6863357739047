.terms_and_conditions_container {
  height: auto;
  align-items: center;
  position: absolute;
  top: 5%;
  left: 0%;
  padding: 30px;

  > div {
    text-align: center;

    .terms_conditions_body {
      max-width: 800px;
      width: 700px;
      min-width: 300px;
      padding: 20px;
      border: 1px solid #d3d3d3;
      text-align: left;
      line-height: 30px;
      margin-top: 10px;
      margin-bottom: 30px;
    }

    .terms_and_conditions_checkbox {
      margin: 0px 0px;
    }
  }
}

.continue_button > button {
  margin-top: 30px;
}
.title {
  font-weight: 600;
}
